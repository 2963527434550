body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.loader {
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #000000; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

video {
    /*height: 100vh !important;*/
    /*width: 100vw !important;*/
    object-fit: cover;
    /*transform: scaleX(-1);*/
    /*z-index: -1;*/
}

canvas {
    /*height: 100vh !important;*/
    /*width: 100vw !important;*/
    object-fit: cover;
    transform: scaleX(-1);
    /*z-index: 999;*/
}

.camera-controls{
    z-index: 99999;
}


@tailwind base;
@tailwind components;
@tailwind utilities;
